import { Application } from "@hotwired/stimulus"
import controllers from "./**/*.{js,js.rb}"

window.Stimulus = Application.start()

Object.entries(controllers).forEach(([filename, controller]) => {
  if (filename.includes("_controller.")) {
    const identifier = filename.replace("./", "")
      .replace(/_controller..*$/, "")
      .replace("_", "-")
      .replace("/", "--")

    Stimulus.register(identifier, controller.default)
  }
})
