import { Controller } from "@hotwired/stimulus"
import { debounce } from "debounce";

export default class extends Controller {
  static targets = ["item", "searchField"]
  static classes = ["enabled", "highlight", "diminish"]
  
  initialize() {
    this.highlightMatching = debounce(this.highlightMatching, 500).bind(this)
  }
  
  connect() {
    this.enable()
  }
  
  disconnect() {
    this.disable()
  }
  
  highlightMatching(event) {
    const q = this.searchFieldTarget.value.trim().toLowerCase()
    if (q === "") {
      this.reset()
      return
    }
    
    this.itemTargets.forEach(item => {
      const words = item.textContent.trim().toLowerCase().replace(/[\(\)]/g, '').split(" ")
      if (words.findIndex(word => {
        return word.substring(0, q.length) === q
      }) !== -1) {
        item.classList.add(this.highlightClass)
        item.classList.remove(this.diminishClass)
      } else {
        item.classList.add(this.diminishClass)
        item.classList.remove(this.highlightClass)
      }
    })
  }
  
  reset() {
    this.itemTargets.forEach(item => {
      item.classList.remove(this.highlightClass)
      item.classList.remove(this.diminishClass)
    })
  }
  
  enable() {
    this.element.classList.add(this.enabledClass)
  }
  
  disable() {
    this.element.classList.remove(this.enabledClass)
  }
}