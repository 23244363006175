import { Controller } from "@hotwired/stimulus"

export default class extends Controller {
  static classes = ["enabled"]
  
  connect() {
    this.enable()
  }
  
  disconnect() {
    this.disable()
  }
  
  enable() {
    this.element.classList.add(this.enabledClass)
  }
  
  disable() {
    this.element.classList.remove(this.enabledClass)
  }
}